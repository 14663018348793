import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guard/AuthGuard';

// ----------------------------------------------------------------------

// const Loadable = (Component) => (props) => {
//   const { pathname } = useLocation();

//   return (
//     <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
//       <Component {...props} />
//     </Suspense>
//   );
// };

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dietitian" replace />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/dietitian',
      element:
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>,
      children: [
        { path: '', element: <Dietitian /> },
        { path: ':id', element: <DietitianAddEdit /> },
        { path: 'add', element: <DietitianAddEdit /> },
        // { path: 'payroll', element: <Payroll /> },

      ],
    },
    {
      path: '/payroll',
      element:
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>,
      children: [
        { path: '', element: <Payroll /> },
      ],
    },
    {
      path: '/weekly-averages',
      element:
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>,
      children: [
        { path: '', element: <WeeklyAverage /> },
      ],
    },
    {
      path: '/dietitiankpis',
      element:
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>,
      children: [
        { path: '', element: <DietitianKPI /> },
      ],
    },
    // {
    //   path: '*',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" replace /> },
    //   ],
    // },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}


// Dashboard
const Dietitian = Loadable(lazy(() => import('../pages/Dietitians')));
const DietitianAddEdit = Loadable(lazy(() => import('../pages/AddEditDietitian')));
const Payroll = Loadable(lazy(() => import('../pages/Payroll')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const WeeklyAverage = Loadable(lazy(() => import('../pages/WeeklyAverage')));
const DietitianKPI = Loadable(lazy(() => import('../pages/DietitianKPI')));
// const NotFound = Loadable(lazy(() => import('../pages/Page404')));
