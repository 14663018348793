import { useState, useEffect } from 'react';

const useAuthUser = () => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    // Fetch user data from localStorage
    const userData = localStorage.getItem('user');
    if (userData) {
      // Parse user data from JSON string
      const parsedUserData = JSON.parse(userData);
      // Set user data to state
      setAuthUser(parsedUserData);
    }
  }, []);

  return {...authUser};
};

export default useAuthUser;
