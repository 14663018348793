export const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  export const checkIsNull =(value)=>{
     if(!value || value.trim() === '') return true
    return false
    }

    export const logout=()=>{
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      window.location.replace('/')
    }